<template>
  <div class="card">
    <div class="row">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>
    <div class="row" v-if="loading == false">
      <div class="col-12 col-sm-12">
        <h2>
          Optimization Opportunities:
          <strong class="ml-2">{{
            optimizer_data.total_optimized_lines
          }}</strong>
        </h2>
      </div>
      <div class="col-12 col-sm-12 col-md-6 mt-4 text-center current-balance">
        <div class="row">
          <div class="col-12 col-sm-12 px-0 br-lg-1">
            <p class="title color-gray">You could save</p>
            <p class="cost color-orange">
              {{ optimizer_data.total_savings | currencyDolar }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 mt-4 text-center avg-cost-user">
        <div class="row">
          <div class="col-12 col-sm-12 px-0">
            <p class="title color-gray">Reduce your invoice by</p>
            <p class="cost">
              {{ optimizer_data.saving_percentage | percentage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["optimizer_data", "loading"]
};
</script>
