<template>
  <div class="modal-expertel container-fluid">
    <div class="modal-container large ">
      <div class="header-modal">
        <button class="close-modal" @click="showModal()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="body-modal scrollable">
        <div id="content-modal">
          <h4 class="text-center">
            Let's preview your changes:
          </h4>
          <!--<p class="text-center">You selected the following options:</p>-->
          <div class="height-80">
            <div v-if="linesOptimizationSelected.length > 0">
              <h2 class="font-weight-normal mt-4 pt-4">
                Data Pool Recommendations
              </h2>
              <div class="line-blue-3 mb-4"></div>
              <table class="table">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Phone Line</th>
                    <th>Recommended Add-on</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in linesOptimizationSelected"
                    :key="item.phone_number"
                  >
                    <td>{{ item.user_name }}</td>
                    <td>{{ item.phone_number | phoneNumber }}</td>
                    <td>{{ item.plan_name }}</td>
                    <td>1</td>
                    <td>${{ item.cost_difference * -1 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="linesOptimizationBucketsSelected.length > 0">
              <h2 class="font-weight-normal mt-4 pt-4">
                Data Pool Recommendations
              </h2>
              <div class="line-blue-3 mb-4"></div>
              <table class="table">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Phone Line</th>
                    <th>Recommended Add-on</th>
                    <th>Add-on Cost</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, key) in linesOptimizationBucketsSelected"
                    :key="key"
                  >
                    <td>{{ item.user_name }}</td>
                    <td>{{ item.phone_number | phoneNumber }}</td>
                    <td>{{ item.bucket["feature_name"] }}</td>
                    <td>{{ item.bucket["feature_price"] }}</td>
                    <td>{{ item.bucket["count"] }}</td>
                    <td>
                      <span v-if="item.bucket['feature_price']"
                        >${{
                          parseFloat(
                            item.bucket["feature_price"] * item.bucket["count"]
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="linesSuspensionsSelected.length > 0">
              <h2 class="font-weight-normal mt-4 pt-4">
                Recommended Line Suspensions
              </h2>
              <div class="line-blue-3 mb-4"></div>
              <table class="table">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Phone Line</th>
                    <th>Projected Savings in 1 month</th>
                    <th>Projected Savings in 3 months</th>
                    <th>Projected Savings in 6 months</th>
                    <th>Priority</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr v-for="item in recomendations" :key="item.id"> -->
                  <tr
                    v-for="(item, key) in linesSuspensionsSelected"
                    :key="key"
                  >
                    <td>{{ item.user_name }}</td>
                    <td>{{ item.phone_number | phoneNumber }}</td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[0]">{{
                        checkNumber(item.savings[0].savings)
                      }}</span>
                    </td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[1]">{{
                        checkNumber(item.savings[1].savings)
                      }}</span>
                    </td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[2]">{{
                        checkNumber(item.savings[2].savings)
                      }}</span>
                    </td>
                    <td>
                      {{ item.status }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="linesFeaturesSelected.length > 0">
              <h2 class="font-weight-normal mt-4 pt-4">
                Recommended Talk and Text Feature Additions
              </h2>

              <div class="line-blue-3 mb-4"></div>
              <table class="table">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Phone Line</th>
                    <th>Projected Savings in 1 month</th>
                    <th>Projected Savings in 3 months</th>
                    <th>Projected Savings in 6 months</th>
                    <th>Priority</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in linesFeaturesSelected" :key="key">
                    <td>{{ item.user_name }}</td>
                    <td>{{ item.phone_number | phoneNumber }}</td>
                    <td class="color-success font-circular font-bold">
                      {{ checkNumber(item.savings[0].savings) }}
                    </td>
                    <td class="color-success font-circular font-bold">
                      {{ checkNumber(item.savings[1].savings) }}
                    </td>
                    <td class="color-success font-circular font-bold">
                      {{ checkNumber(item.savings[2].savings) }}
                    </td>
                    <td>
                      {{ item.status }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p class="text-center color-gray mt-4">
            By confirming this request, your total savings will be:
          </p>
          <p class="text-center color-blue price mt-4">
            {{ sumTotalSaving | currencyDolar }}
          </p>

          <div class="col-12 col-sm-12" v-if="loading_ajax">
            <grid-loader
              class="expertel-loader"
              color="#ff502d"
              :size="20"
              sizeUnit="px"
            ></grid-loader>
          </div>

          <p class="text-center mt-4">
            <button class="button-expertel btn-big" @click="sendRequest()">
              REQUEST CHANGE
            </button>
          </p>
          <p class="text-center color-gray mt-4">
            You'll be receiving a confirmation email once these changes are
            completed by our team in the next few hours.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "optimizer_data",
    "selectedOptimization",
    "selectedOptimizationBuckets",
    "selectedSuspensions",
    "selectedFeatures",
    "login_data"
  ],
  data() {
    return {
      //loading: false,

      optization: [],
      optizationBuckets: [],
      suspensions: [],
      features: [],

      totalSaving: 0.0,

      loading_ajax: ""
    };
  },
  async created() {
    // this.loading = false;
  },
  watch: {
    /*
    loading_ajax: function(newStatus) {
      console.log(newStatus);
    }
    */
  },
  methods: {
    showModal() {
      this.$emit("showModal");
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    sendRequest() {
      const self = this;
      self.loading_ajax = true;

      if (this.login_data !== undefined || this.login_data.success === false) {
        if (!this.login_data.success) {
          this.$router.push("/");
        }
      } else {
        this.$router.push("/");
      }

      //console.log(this.loading_ajax);
      //FROMDATA TO SEND
      //let formData = new FormData();

      //===========================
      //Optimization
      //===========================

      //console.log(arrOptization);
      let client_account_id = self.optimizer_data.client_account_id;
      let client_report_id = self.optimizer_data.client_report_id;
      //console.log(self.optization)

      //===========================
      //Suspenses
      //===========================
      let arrSuspensions = [];
      if (self.suspensions.length > 0) {
        self.suspensions.forEach(function(item) {
          arrSuspensions.push(item.id);
        });
      }
      //console.log(arrSuspensions);

      //===========================
      //Features
      //===========================
      let arrFeatures = [];
      if (self.features.length > 0) {
        self.features.forEach(function(item) {
          arrFeatures.push(item.id);
        });
      }
      //console.log(arrFeatures);

      let dataToSend = {
        client_account_id: client_account_id,
        client_report_id: client_report_id,
        data_buckets: self.optizationBuckets,
        data: self.optization,
        suspend: arrSuspensions,
        features: arrFeatures
      };

      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/optimizer_change_request`,
        responseType: "json",
        data: dataToSend,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.data.success == false) {
            self.makeToast(response.data.errors, "Error to send the data");
            self.loading_ajax = false;
          } else {
            //self.$emit("showModal");
            //self.makeToast("Success", "Your request has been successfully submitted", "success");

            self.$emit(
              "update:cats",
              "Your request has been successfully submitted"
            );
            self.$emit("showModal");
            self.loading_ajax = false;
          }
        })
        .catch(
          error => self.makeToast("ERROR", error, "danger"),
          (self.loading_ajax = false)
        );
    },
    checkNumber(value) {
      if (isNaN(value)) {
        return value;
      } else {
        return "$" + value;
      }
    }
  },

  computed: {
    linesOptimizationSelected: function() {
      const self = this;
      let getData = [];

      //To send the Request Change
      let arrMain = [];

      this.optimizer_data.data_optimization.items.forEach(function(e) {
        //To send the Request Change
        let subaccount = e.subaccount;
        let arrItems = [];

        let obj = e.changes;
        let dataopt = Object.values(obj);
        dataopt.forEach(function(changes) {
          let x = self.selectedOptimization.indexOf(changes.index);
          if (x !== -1) {
            getData.push({
              phone_number: changes.phone_number,
              plan_name: changes.plan_name,
              plan_cost: changes.plan_cost,
              cost_difference: changes.cost_difference,
              current_plan_name: changes.current_plan_name,
              current_plan_cost: changes.current_plan_cost,
              user_name: changes.user_name,
              index: changes.index
            });

            //To send the Request Change
            arrItems.push(changes.index);
          }
        });
        //To send the Request Change
        if (arrItems.length > 0) {
          arrMain.push({ subaccount: subaccount, items: arrItems });
        }
      });
      self.optization = arrMain;
      return getData;
    },

    linesOptimizationBucketsSelected: function() {
      const self = this;
      let getData = [];

      //To send the Request Change
      let arrMain = [];

      this.optimizer_data.data_optimization.items.forEach(function(e) {
        //To send the Request Change
        let subaccount = e.subaccount;
        let arrItems = [];

        let obj = e.buckets;
        let dataopt = Object.values(obj);

        dataopt.forEach(function(buckets) {
          //console.log(buckets.bucket.id);
          let x = self.selectedOptimizationBuckets.indexOf(buckets.bucket.id);
          if (x !== -1) {
            getData.push({
              phone_number: buckets.phone_number,
              user_name: buckets.user_name,

              bucket: {
                id: buckets.bucket.id,
                feature_name: buckets.bucket.feature_name,
                provider_id: buckets.bucket.provider_id,
                feature_price: buckets.bucket.feature_price,
                usage_limit: buckets.bucket.usage_limit,
                type: buckets.bucket.type,
                smb_enabled: buckets.bucket.smb_enabled,
                corporate_enabled: buckets.bucket.corporate_enabled,
                count: buckets.bucket.count
              }
            });
            //To send the Request Change
            arrItems.push(buckets.bucket.id);
          }
        });
        //To send the Request Change
        if (arrItems.length > 0) {
          arrMain.push({ subaccount: subaccount, items: arrItems });
        }
      });
      self.optizationBuckets = arrMain;
      return getData;
    },

    linesSuspensionsSelected: function() {
      const self = this;
      let data = this.optimizer_data.suspend_optimization.items.filter(function(
        e
      ) {
        let x = self.selectedSuspensions.indexOf(e.id);
        return x !== -1;
      });

      self.suspensions = data;
      return data;
    },

    linesFeaturesSelected: function() {
      const self = this;
      let data = this.optimizer_data.feature_optimization.items.filter(function(
        e
      ) {
        let x = self.selectedFeatures.indexOf(e.id);
        return x !== -1;
      });

      self.features = data;
      return data;
    },

    sumTotalSaving: function() {
      const self = this;

      self.features.forEach(function(e) {
        e.savings.forEach(function(s) {
          if (!isNaN(s.savings)) {
            self.totalSaving = self.totalSaving + s.savings;
          }
        });
      });

      self.suspensions.forEach(function(e) {
        e.savings.forEach(function(s) {
          if (!isNaN(s.savings)) {
            self.totalSaving = self.totalSaving + s.savings;
          }
        });
      });
      return self.totalSaving;
    }
  }
};
</script>

<style lang="scss" scoped>
.table thead th {
  border-top: 0;
  border-bottom: 0;
}
.table {
  tr {
    &:first-child td {
      border-top: 0;
    }
  }
}
.scrollable {
  overflow: scroll;
  height: 95%;
}
.modal-expertel .modal-container {
  padding: 3rem 1.2rem 0rem 0rem;
}

#content-modal {
  padding: 3rem 2rem 3rem 5rem;
}
</style>
