<template>
  <div class="container-fluid px-0 px-md-4">
    <div class="row pb-4 mt-9px">
      <div class="col-12 col-sm-12 col-lg-6">
        <OptOppor
          :optimizer_data="optimizer_data"
          :loading="loading_optimizer_data"
        />
      </div>
      <div class="col-12 col-sm-12 col-lg-6 mt-4 mt-lg-0">
        <AccountStatus
          :optimizer_data="optimizer_data"
          :loading="loading_optimizer_data"
        />
      </div>
      <div class="col-12 col-sm-12 mt-4">
        <div class="card">
          <DataPoolOptimizer
            :optimizer_data="optimizer_data"
            :loading="loading_optimizer_data"
            :current_id_report="current_id_report"
            :login_data="login_data"
            :subAccounts="subAccounts"
          />
          <DataOptimization
            :optimizer_data="optimizer_data"
            :loading="loading_optimizer_data"
            :cats.sync="selectedOptimization"
          />

          <DataOptimizationBuckets
            :optimizer_data="optimizer_data"
            :loading="loading_optimizer_data"
            :cats.sync="selectedOptimizationBuckets"
          />
        </div>
      </div>
      <div class="col-12 col-sm-12 mt-4">
        <RecommendedLineSuspensions
          :optimizer_data="optimizer_data"
          :loading="loading_optimizer_data"
          :cats.sync="selectedSuspensions"
        />
      </div>
      <div class="col-12 col-sm-12 mt-4">
        <RecommendedTalkTextFeatureAdditions
          :optimizer_data="optimizer_data"
          :loading="loading_optimizer_data"
          :cats.sync="selectedFeatures"
        />
      </div>
      <div class="col-12 col-sm-12 mt-4 text-center">
        <button class="button-expertel btn-big" @click="showModal()">
          REQUEST CHANGE
        </button>
      </div>
      <transition name="fade">
        <div class="col-12 col-sm-12 mt-4" v-if="show_modal === true">
          <SuccessSubmited
            @showModal="showModal()"
            :login_data="login_data"
            :optimizer_data="optimizer_data"
            :loading="loading_optimizer_data"
            :selectedOptimization="selectedOptimization"
            :selectedOptimizationBuckets="selectedOptimizationBuckets"
            :selectedSuspensions="selectedSuspensions"
            :selectedFeatures="selectedFeatures"
            :cats.sync="result"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import OptOppor from "@/components/optimizer/OptimizationOpportunities.vue";
import AccountStatus from "@/components/optimizer/AccountStatus.vue";
import DataPoolOptimizer from "@/components/optimizer/DataPoolOptimizer.vue";
import DataOptimization from "@/components/optimizer/DataOptimization.vue";
import DataOptimizationBuckets from "@/components/optimizer/DataOptimizationBuckets.vue";
import RecommendedLineSuspensions from "@/components/optimizer/RecommendedLineSuspensions.vue";
import RecommendedTalkTextFeatureAdditions from "@/components/optimizer/RecommendedTalkTextFeatureAdditions.vue";
import SuccessSubmited from "@/components/optimizer/SuccessSubmited.vue";
export default {
  props: ["login_data", "id_report"],
  components: {
    OptOppor,
    AccountStatus,
    DataPoolOptimizer,
    DataOptimization,
    DataOptimizationBuckets,
    RecommendedLineSuspensions,
    RecommendedTalkTextFeatureAdditions,
    SuccessSubmited
  },
  data() {
    return {
      show_modal: false,
      optimizer_data: [],
      loading_optimizer_data: true,
      current_id_report: false,
      subAccounts: [],

      selectedOptimization: [],
      selectedOptimizationBuckets: [],
      selectedSuspensions: [],
      selectedFeatures: [],

      result: ""
    };
  },

  methods: {
    showModal() {
      if (
        this.selectedOptimization.length > 0 ||
        this.selectedOptimizationBuckets.length > 0 ||
        this.selectedSuspensions.length > 0 ||
        this.selectedFeatures.length > 0
      ) {
        if (this.result) {
          this.makeToast("Success", this.result, "success");
          this.result = "";
          this.getData();
        }
        this.show_modal = !this.show_modal;
      } else {
        this.makeToast("Error", "Select at least one item", "danger");
      }
    },
    getData() {
      const self = this;
      this.loading_optimizer_data = true;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/optimizer`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.optimizer_data = response.data;
          self.loading_optimizer_data = false;

          let info = response.data.data_optimization.items;
          //let arr = [];
          info.forEach(function(value) {
            //console.log(value.subaccount);
            //arr.push(value.subaccount);
            self.subAccounts.push(value.subaccount);
          });

          //self.subAccounts = arr.join();
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));

      this.current_id_report = self.id_report;
    },

    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    }
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getData();
      }
    } else {
      this.$router.push("/");
    }
  }
};
</script>
