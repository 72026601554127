<template>
  <div class="card">
    <div class="row mt-4">
      <div class="col-12 col-sm-12 col-md-6">
        <h2 class="font-weight-normal">
          Recommended Talk and Text Feature Additions
        </h2>
      </div>
      <div class="col-12 col-sm-12 col-md-6 mt-3 mt-md-0">
        <div class="box-gray-3 px-3 py-2">
          <p
            class="mb-0 h5 text-center"
            v-if="optimizer_data.feature_optimization.summary.count == 0"
          >
            Congratulations! You have no lines to optimize.
          </p>
          <p class="mb-0 h5 text-center" v-else>
            By modifying
            <strong v-if="optimizer_data.feature_optimization"
              >{{
                optimizer_data.feature_optimization.summary.count
              }}
              lines</strong
            >
            you will save
            <strong v-if="optimizer_data.feature_optimization">{{
              optimizer_data.feature_optimization.summary.total | currencyDolar
            }}</strong>
            per month
          </p>
        </div>
      </div>
      <div
        class="col-12 col-sm-12 mt-3 mt-md-0"
        v-if="
          linesHightPriority.length !== 0 ||
            linesMediumPriority.length !== 0 ||
            linesLowPriority.length !== 0
        "
      >
        <p>Please select priority:</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <b-tabs pills card class="recommended-lines-tabs">
          <b-tab
            title="High Priority"
            :active="linesHightPriority.length !== 0 ? true : false"
          >
            <div class="line-blue-3 mb-4"></div>
            <div
              class="table-fixed-header"
              v-if="linesHightPriority.length !== 0"
            >
              <table class="table-recommended">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        v-model="selectAllHigh"
                      />
                    </th>
                    <th>User Name</th>
                    <th>Phone Line</th>
                    <th class="text-center">Project 1 month Savings</th>
                    <th class="text-center">Project 3 months Savings</th>
                    <th class="text-center">Project 6 months Savings</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in linesHightPriority" :key="key">
                    <td>
                      <span v-if="item.submitted">
                        <span>&#10003;</span>
                      </span>
                      <span v-else>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          :value="item.id"
                          v-model="selectedHigh"
                        />
                      </span>
                    </td>
                    <td>{{ item.user_name }}</td>
                    <td>{{ item.phone_number | phoneNumber }}</td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[0]">{{
                        checkNumber(item.savings[0].savings)
                      }}</span>
                    </td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[1]">{{
                        checkNumber(item.savings[1].savings)
                      }}</span>
                    </td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[2]">{{
                        checkNumber(item.savings[2].savings)
                      }}</span>
                    </td>
                    <td>
                      <i
                        :id="`rtfa1-info-${key + 1}`"
                        class="fas fa-info-circle"
                      ></i>
                      <b-tooltip
                        :target="`rtfa1-info-${key + 1}`"
                        placement="bottomleft"
                      >
                        <div class="px-2 py-3">
                          <table class="all-text-white">
                            <thead>
                              <tr>
                                <th class="px-2">
                                  <strong>Months</strong>
                                </th>
                                <th class="px-2">
                                  <strong>Charges</strong>
                                </th>
                                <th class="px-2"><strong>Price</strong></th>
                                <th class="px-2">
                                  <strong>Savings</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(ex, id) in item.savings" :key="id">
                                <td>{{ ex.month_count }}</td>
                                <td>{{ checkNumber(ex.charge) }}</td>
                                <td>{{ checkNumber(ex.price) }}</td>
                                <td>{{ checkNumber(ex.savings) }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2 v-else>You don't have lines with high priority</h2>
          </b-tab>
          <b-tab
            title="Medium Priority"
            :active="
              linesHightPriority.length == 0 && linesMediumPriority.length !== 0
                ? true
                : false
            "
          >
            <div class="line-blue-3 mb-4"></div>
            <div
              class="table-fixed-header"
              v-if="linesMediumPriority.length !== 0"
            >
              <table class="table-recommended">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        v-model="selectAllMedium"
                      />
                    </th>
                    <th>User Name</th>
                    <th>Phone Line</th>
                    <th class="text-center">Project 1 month Savings</th>
                    <th class="text-center">Project 3 months Savings</th>
                    <th class="text-center">Project 6 months Savings</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in linesMediumPriority" :key="key">
                    <td>
                      <span v-if="item.submitted">
                        <span>&#10003;</span>
                      </span>
                      <span v-else>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          :value="item.id"
                          v-model="selectedMedium"
                        />
                      </span>
                    </td>
                    <td>{{ item.user_name }}</td>
                    <td>{{ item.phone_number | phoneNumber }}</td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[0]">{{
                        checkNumber(item.savings[0].savings)
                      }}</span>
                    </td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[1]">{{
                        checkNumber(item.savings[1].savings)
                      }}</span>
                    </td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[2]">{{
                        checkNumber(item.savings[2].savings)
                      }}</span>
                    </td>
                    <td>
                      <i
                        :id="`rtfa2-info-${key + 1}`"
                        class="fas fa-info-circle"
                      ></i>
                      <b-tooltip
                        :target="`rtfa2-info-${key + 1}`"
                        placement="bottomleft"
                      >
                        <div class="px-2 py-3">
                          <table class="all-text-white">
                            <thead>
                              <tr>
                                <th class="px-2">
                                  <strong>Months</strong>
                                </th>
                                <th class="px-2">
                                  <strong>Charges</strong>
                                </th>
                                <th class="px-2"><strong>Price</strong></th>
                                <th class="px-2">
                                  <strong>Savings</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(ex, id) in item.savings" :key="id">
                                <td>{{ ex.month_count }}</td>
                                <td>{{ checkNumber(ex.charge) }}</td>
                                <td>{{ checkNumber(ex.price) }}</td>
                                <td>{{ checkNumber(ex.savings) }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2 v-else>You don't have lines with medium priority</h2>
          </b-tab>
          <b-tab
            title="Low Priority"
            :active="
              linesHightPriority.length == 0 &&
              linesMediumPriority.length == 0 &&
              linesLowPriority.length !== 0
                ? true
                : false
            "
          >
            <div class="line-blue-3 mb-4"></div>

            <div
              class="table-fixed-header"
              v-if="linesLowPriority.length !== 0"
            >
              <table class="table-recommended">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        v-model="selectAllLow"
                      />
                    </th>
                    <th>User Name</th>
                    <th>Phone Line</th>
                    <th class="text-center">Project 1 month Savings</th>
                    <th class="text-center">Project 3 months Savings</th>
                    <th class="text-center">Project 6 months Savings</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in linesLowPriority" :key="key">
                    <td>
                      <span v-if="item.submitted">
                        <span>&#10003;</span>
                      </span>
                      <span v-else>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          :value="item.id"
                          v-model="selectedLow"
                        />
                      </span>
                    </td>
                    <td>{{ item.user_name }}</td>
                    <td>{{ item.phone_number | phoneNumber }}</td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[0]">{{
                        checkNumber(item.savings[0].savings)
                      }}</span>
                    </td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[1]">{{
                        checkNumber(item.savings[1].savings)
                      }}</span>
                    </td>
                    <td
                      class="color-success font-circular text-center font-bold"
                    >
                      <span v-if="item.savings[2]">{{
                        checkNumber(item.savings[2].savings)
                      }}</span>
                    </td>
                    <td>
                      <i
                        :id="`rtfa3-info-${key + 1}`"
                        class="fas fa-info-circle"
                      ></i>
                      <b-tooltip
                        :target="`rtfa3-info-${key + 1}`"
                        placement="bottomleft"
                      >
                        <div class="px-2 py-3">
                          <table class="all-text-white">
                            <thead>
                              <tr>
                                <th class="px-2">
                                  <strong>Months</strong>
                                </th>
                                <th class="px-2">
                                  <strong>Charges</strong>
                                </th>
                                <th class="px-2"><strong>Price</strong></th>
                                <th class="px-2">
                                  <strong>Savings</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(ex, id) in item.savings" :key="id">
                                <td>{{ ex.month_count }}</td>
                                <td>{{ checkNumber(ex.charge) }}</td>
                                <td>{{ checkNumber(ex.price) }}</td>
                                <td>{{ checkNumber(ex.savings) }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2 v-else>You don't have lines with low priority</h2>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["optimizer_data", "loading"],
  data() {
    return {
      selectedHigh: [],
      selectedMedium: [],
      selectedLow: []
    };
  },
  created() {},
  watch: {
    optimizer_data: function() {
      this.selectedHigh = [];
      this.selectedMedium = [];
      this.selectedLow = [];
    }
  },
  methods: {
    setSet(value, level) {
      var selected = [];
      if (value) {
        this.optimizer_data.feature_optimization.items.forEach(function(
          recomendation
        ) {
          if (recomendation.status == level) {
            selected.push(recomendation.id);
          }
        });
      }

      switch (level) {
        case "High":
          this.selectedHigh = selected;
          break;
        case "Medium":
          this.selectedMedium = selected;
          break;
        case "Low":
          this.selectedLow = selected;
          break;
      }

      const array3 = this.selectedHigh.concat(
        this.selectedMedium,
        this.selectedLow
      );
      this.$emit("update:cats", array3);
    },

    setGet(level) {
      let v = "";
      switch (level) {
        case "High":
          v = this.optimizer_data.feature_optimization.items
            ? this.selectedHigh.length ==
              this.optimizer_data.feature_optimization.items.length
            : false;
          break;
        case "Medium":
          v = this.optimizer_data.feature_optimization.items
            ? this.selectedMedium.length ==
              this.optimizer_data.feature_optimization.items.length
            : false;
          break;
        case "Low":
          v = this.optimizer_data.feature_optimization.items
            ? this.selectedLow.length ==
              this.optimizer_data.feature_optimization.items.length
            : false;
          break;
      }

      const array3 = this.selectedHigh.concat(
        this.selectedMedium,
        this.selectedLow
      );
      this.$emit("update:cats", array3);
      return v;
    },
    checkNumber(value) {
      if (isNaN(value)) {
        return value;
      } else {
        return "$" + value.toFixed(2);
      }
    }
  },
  computed: {
    //Status Hight
    selectAllHigh: {
      get: function() {
        return this.setGet("High");
      },
      set: function(value) {
        this.setSet(value, "High");
      }
    },

    //Status Meidum
    selectAllMedium: {
      get: function() {
        return this.setGet("Medium");
      },
      set: function(value) {
        this.setSet(value, "Medium");
      }
    },

    //Status Low
    selectAllLow: {
      get: function() {
        return this.setGet("Low");
      },
      set: function(value) {
        this.setSet(value, "Low");
      }
    },

    linesHightPriority: function() {
      return this.optimizer_data.feature_optimization.items.filter(
        item => item.status == "High"
      );
    },
    linesMediumPriority: function() {
      return this.optimizer_data.feature_optimization.items.filter(
        line => line.status == "Medium"
      );
    },
    linesLowPriority: function() {
      //return this.optimizer_data.feature_optimization.items;

      return this.optimizer_data.feature_optimization.items.filter(
        line => line.status == "Low"
      );
    }
  }
};
</script>
