<template>
  <div class="cardx">
    <div class="row">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>

    <div v-if="loading == false">
      <div
        v-for="(data, index) in optimizer_data.data_optimization.items"
        :key="index"
      >
        <div class="row">
          <div class="col-12 col-sm-12">
            <h2>
              Data Pool Optimizer (Account: {{ data.subaccount | phoneNumber }})
            </h2>
          </div>
        </div>

        <div class="row">
          <div
            class="col-12 col-sm-6 col-lg-3 mt-4 text-center current-balance"
          >
            <div class="row">
              <div class="col-12 col-sm-12 px-0 br-lg-1">
                <p class="title color-gray">Current Data Pool</p>
                <p class="cost" v-if="data.data_pool_info.old">
                  {{ data.data_pool_info.old | dataGB }}
                </p>
                <p class="cost" v-else>{{ data.data_pool_info.old }} GB</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-3 mt-4 text-center avg-cost-user">
            <div class="row">
              <div class="col-12 col-sm-12 px-0 br-lg-1">
                <p class="title color-gray">Recommended Data Pool</p>
                <p class="cost">{{ data.data_pool_info.new | dataGB }}</p>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-6 col-lg-3 mt-4 text-center current-balance"
          >
            <div class="row">
              <div class="col-12 col-sm-12 px-0 br-lg-1">
                <p class="title color-gray">Investment</p>
                <p class="cost" v-if="data.buckets > 0">
                  {{ data.buckets | currencyDolar }}
                </p>
                <p class="cost" v-else>$0.00</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-3 mt-4 text-center avg-cost-user">
            <div class="row">
              <div class="col-12 col-sm-12 px-0">
                <p class="title color-gray">Savings</p>
                <p class="cost">
                  {{ savingsHistoricals(data.historical) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-sm-12">
            <highcharts
              class="optimizerChart"
              :options="generateChart(index)"
            ></highcharts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "optimizer_data",
    "loading",
    "current_id_report",
    "login_data",
    "subAccounts"
  ],
  data() {
    return {
      monthly_data: [],

      recomendations: [
        {
          id: 1,
          userName: "Jake Thompson",
          phoneLine: "(555) 570-5956",
          recommended: "20 GB Data Add-On",
          quantity: 1,
          total: 200
        },
        {
          id: 2,
          userName: "Karen Robert",
          phoneLine: "(555) 570-5956",
          recommended: "100 GB Data Add-On",
          quantity: 1,
          total: 1000
        },
        {
          id: 3,
          userName: "Jake Thompson",
          phoneLine: "(555) 570-5956",
          recommended: "20 GB Data Add-On",
          quantity: 1,
          total: 200
        },
        {
          id: 4,
          userName: "Karen Robert",
          phoneLine: "(555) 570-5956",
          recommended: "100 GB Data Add-On",
          quantity: 1,
          total: 1000
        },
        {
          id: 5,
          userName: "Karen Robert",
          phoneLine: "(555) 570-5956",
          recommended: "100 GB Data Add-On",
          quantity: 1,
          total: 1000
        },
        {
          id: 6,
          userName: "Jake Thompson",
          phoneLine: "(555) 570-5956",
          recommended: "20 GB Data Add-On",
          quantity: 1,
          total: 200
        },
        {
          id: 7,
          userName: "Jake Thompson",
          phoneLine: "(555) 570-5956",
          recommended: "20 GB Data Add-On",
          quantity: 1,
          total: 200
        },
        {
          id: 8,
          userName: "Karen Robert",
          phoneLine: "(555) 570-5956",
          recommended: "100 GB Data Add-On",
          quantity: 1,
          total: 1000
        },
        {
          id: 9,
          userName: "Jake Thompson",
          phoneLine: "(555) 570-5956",
          recommended: "20 GB Data Add-On",
          quantity: 1,
          total: 200
        }
      ],
      stockOptions: null,
      arrMonths: [],
      arrUsage: [],
      arrPool: []
    };
  },
  mounted() {
    this.getCharts();
  },
  methods: {
    async getCharts() {
      let self = this;

      //let arr = [];
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/optimizer`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.optimizer_data = response.data;
          self.loading_optimizer_data = false;

          //console.log(self.optimizer_data);
          self.optimizer_data.data_optimization.items.forEach(async data => {
            //this.subAccounts.forEach( async data => {
            await self
              .axios({
                method: "get",
                //url: `https://api.expertel.ca/api/v1/client/me/reports/${self.current_id_report}/data?type=data_usage&subaccount=${data}`,
                url: `https://api.expertel.ca/api/v1/client/me/reports/${self.current_id_report}/data?type=data_usage&subaccount=${data.subaccount}`,
                responseType: "json",
                headers: {
                  Authorization: "Bearer " + self.login_data.token
                }
              })
              .then(function(result) {
                let months = [],
                  usage = [],
                  pool = [];
                result.data.data.forEach(data => {
                  months.push(data[0]);
                  usage.push(data[1]);
                  pool.push(data[2]);
                });

                self.arrMonths.push(months);
                self.arrUsage.push(usage);
                self.arrPool.push(pool);
              })
              .catch(function(errorx) {
                console.log(errorx);
              });
          });
        })
        .catch(error => this.makeToast("ERROR",error, "danger"));
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },

    generateChart(index) {
      let stockOptions = {
        chart: {
          type: "areaspline"
        },
        credits: {
          enabled: false
        },
        title: {
          text: ""
        },
        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom"
        },
        xAxis: {
          categories: this.arrMonths[index],
          tickmarkPlacement: "on",
          title: {
            enabled: false
          },
          labels: {
            style: {
              fontFamily: "'Circular Book', sans-serif",
              fontSize: "1.35em"
            }
          }
        },
        yAxis: {
          labels: {
            enabled: true,
            formatter() {
              return (
                "<span>" +
                this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " GB</span>"
              );
            },
            style: {
              fontFamily: "'Circular Book', sans-serif",
              fontSize: "1.25em"
            }
          },
          title: {
            enabled: false
          }
        },
        tooltip: {
          formatter() {
            return (
              "<strong>" +
              this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " GB</strong>"
            );
          },
          split: false
        },
        series: [
          {
            name: "Usage",
            data: this.arrUsage[index],
            lineWidth: 4,
            marker: {
              symbol: "circle",
              radius: 6
            },
            color: "#343f63",
            fillColor: "rgba(64, 143, 222, 0)"
          },
          {
            name: "Pool",
            data: this.arrPool[index],
            lineWidth: 4,
            marker: {
              symbol: "circle",
              radius: 6
            },
            color: "#ff502d",
            fillColor: "rgba(52, 63, 99, 0)"
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                yAxis: [
                  {
                    labels: {
                      enabled: false
                    }
                  }
                ],
                xAxis: {
                  labels: {
                    formatter: function() {
                      return this.value.charAt(0);
                    }
                  }
                }
              }
            }
          ]
        }
      };

      return stockOptions;
    },

    savingsHistoricals(data) {
      //toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      let number = 0.0;
      data.forEach(item => {
        number = item.charge;
      });

      return "$" + number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>
