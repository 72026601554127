<template>
  <div class="card">
    <div class="row">
      <div class="col-12 col-sm-12">
        <grid-loader
          class="expertel-loader"
          color="#ff502d"
          :loading="loading"
          :size="20"
          sizeUnit="px"
        ></grid-loader>
      </div>
    </div>
    <div class="row" v-if="loading == false">
      <div class="col-12 col-sm-12 d-flex align-items-center">
        <h2>Account Optimization Status</h2>
        <i id="status-info" class="fal fa-info-circle ml-3"></i>
        <b-tooltip target="status-info" placement="right">
          The below percentages represent the level of urgency of optimization
          opportunities.
        </b-tooltip>
      </div>
      <div
        class="col-6 col-sm-6 col-lg-3 mt-4 text-center current-balance"
        v-for="(data, index) in optimizer_data.optimize_line_counts"
        :key="index"
      >
        <div class="row">
          <div
            class="col-12 col-sm-12 px-0"
            :class="
              index % 2 !== 0
                ? index + 1 < optimizer_data.optimize_line_counts.length
                  ? 'br-lg-1'
                  : ''
                : index + 1 < optimizer_data.optimize_line_counts.length
                ? 'br-1'
                : ''
            "
          >
            <p class="title color-gray" v-if="data.label == 'All Good'">
              Fully Optimized
            </p>
            <p class="title color-gray" v-else>{{ data.label }} Priority</p>
            <p class="cost" v-if="data.percentage">
              {{ data.percentage | percentage }}
            </p>
            <p class="cost" v-else>{{ data.percentage }}%</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["optimizer_data", "loading"]
};
</script>
