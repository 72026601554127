<template>
  <div>
    <div v-if="getEntries() > 0">
      <div class="row mt-4" v-if="loading == false">
        <div class="col-12 col-sm-12 col-md-6">
          <h4 class="font-weight-normal">Data Pool Recommendations</h4>
        </div>
        <div class="col-12 col-sm-12 col-md-6 mt-3 mt-md-0">
          <div class="box-gray-3 px-3 py-2">
            <p class="mb-0 h5 text-center">
              By modifying
              <strong
                >{{
                  optimizer_data.data_optimization.summary.count
                }}
                lines</strong
              >
              you can prevent
              <strong>{{
                optimizer_data.data_optimization.summary.total | currencyDolar
              }}</strong>
              in overages
            </p>
          </div>
        </div>
      </div>
      <div class="row my-4" v-if="loading == false">
        <div class="col-12 col-sm-12">
          <div class="line-blue-3 mb-4"></div>
          <div class="table-fixed-header">
            <table class="table-recommended">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      class=""
                      name=""
                      id=""
                      v-model="selectAll"
                    />
                  </th>
                  <th>User Name</th>
                  <th>Phone Line</th>
                  <th>Recommended Add-on</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody
                v-for="(data, key) in optimizer_data.data_optimization.items"
                :key="key"
              >
                <tr v-for="(item, key2) in data.changes" :key="key2">
                  <td>
                    <span v-if="item.submitted">
                      <span>&#10003;</span>
                    </span>
                    <span v-else>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        :value="item.index"
                        v-model="selected"
                      />
                    </span>
                  </td>
                  <td>{{ item.user_name }}</td>
                  <td>{{ item.phone_number | phoneNumber }}</td>
                  <td>{{ item.plan_name }}</td>
                  <td>1</td>
                  <td>
                    <span v-if="item.cost_difference"
                      >${{ parseFloat(item.cost_difference) * -1 }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["optimizer_data", "loading"],
  data() {
    return {
      selected: []
    };
  },
  async created() {},
  watch: {
    optimizer_data: function() {
      this.selected = [];
    }
  },
  methods: {
    getEntries() {
      let rows = 0;
      this.optimizer_data.data_optimization.items.forEach(function(
        recomendation
      ) {
        let obj = recomendation.changes;
        rows = obj.length;
      });
      return rows;
    }
  },
  computed: {
    selectAll: {
      get: function() {
        let v = this.optimizer_data.data_optimization.items
          ? this.selected.length ==
            this.optimizer_data.data_optimization.items.length
          : false;

        console.log(this.selected);
        this.$emit("update:cats", this.selected);
        return v;
      },
      set: function(value) {
        var selected = [];

        if (value) {
          this.optimizer_data.data_optimization.items.forEach(function(
            recomendation
          ) {
            let obj = recomendation.changes;
            let dataopt = Object.values(obj);
            dataopt.forEach(function(changes) {
              selected.push(changes.index);
            });
          });
        }

        this.selected = selected;
        this.$emit("update:cats", selected);
      }
    }
  }
};
</script>
